(function() {
  var s = document.getElementsByTagName("script");
  var d = s[s.length - 1].src.substring(
    0,
    s[s.length - 1].src.lastIndexOf("/") + 1
  );
  for (var i = 0; i < arguments.length; i++) {
    document.write(
      '<script type="text/javascript" src="' + d + arguments[i] + '"></script>'
    );
  }
})(
  "animatedscrolltotop.js",
  "tab.js",
  "slick.min.js",
  "css3-mediaqueries.js",
  "collapser.js",
  "modernizr.custom.js"
);
